import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable({
	providedIn: 'root'
})
export class GetPlatformService {
	isDesktop: boolean;

	constructor(public platform: Platform, @Inject(PLATFORM_ID) private platformId: Object) {

	}

	getPlatformType() {
		this.isDesktop = !this.platform.is("iphone") && !this.platform.is("ios") && !this.platform.is("android") && !this.platform.is("mobile") && !this.platform.is("mobileweb") && !this.platform.is("capacitor");
		let widthApp;
		if (!isPlatformServer(this.platformId)) {
			widthApp = window.innerWidth;
		}
		if (widthApp > 990) {
			this.isDesktop = true;
		}

	}
}

import { CommonModule, DatePipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NativeGeocoder } from '@awesome-cordova-plugins/native-geocoder/ngx';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { CodeInputModule } from 'angular-code-input';
import { NzRateModule } from 'ng-zorro-antd/rate';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { NgxPaginationModule } from 'ngx-pagination';
import { CommonPipe } from '../_pipe/common.pipe';
import { LimitTo } from '../_pipe/limitt-to-directive';
import { AccountInformationComponent } from '../components/account-information/account-information.component';
import { AddressComponent } from '../components/address/address.component';
import { BannerComponent } from '../components/banner/banner.component';
import { CancelProductComponent } from '../components/cancel-product/cancel-product.component';
import { CartHeaderComponent } from '../components/cart-header/cart-header.component';
import { CategoriesComponent } from '../components/categories/categories.component';
import { ChangePasswordComponent } from '../components/change-password/change-password.component';
import { CommonButtonComponent } from '../components/common-button/common-button.component';
import { DesignAndColorComponent } from '../components/design-and-color/design-and-color.component';
import { ExploreCatelogueComponent } from '../components/explore-catelogue/explore-catelogue.component';
import { FilterComponent } from '../components/filter/filter.component';
import { FooterComponent } from '../components/footer/footer.component';
import { ForgotPasswordComponent } from '../components/forgot-password/forgot-password.component';
import { HeaderComponent } from '../components/header/header.component';
import { HomeTitleComponent } from '../components/home-title/home-title.component';
import { HorizontalWishlistComponent } from '../components/horizontal-wishlist/horizontal-wishlist.component';
import { LandingScreenComponent } from '../components/landing-screen/landing-screen.component';
import { LoaderComponent } from '../components/loader/loader.component';
import { LoginPropmptComponent } from '../components/login-prompt/login-prompt.component';
import { MenusComponent } from '../components/menus/menus.component';
import { MyAccountMenuComponent } from '../components/my-account-menu/my-account-menu.component';
import { MyAccountComponent } from '../components/my-account/my-account.component';
import { MyQuoteRequestComponent } from '../components/my-quote-request/my-quote-request.component';
import { NoInternetComponent } from '../components/no-internet/no-internet.component';
import { NotFoundComponent } from '../components/not-found/not-found.component';
import { NotificationCategoryComponent } from '../components/notification-category/notification-category.component';
import { NotificationComponent } from '../components/notification/notification.component';
import { NotifyMeComponent } from '../components/notify-me/notify-me.component';
import { OfferDialogComponent } from '../components/offer-dialog/offer-dialog.component';
import { OrderDetailsComponent } from '../components/order-details/order-details.component';
import { OrderSampleComponent } from '../components/order-sample/order-sample.component';
import { OrderSummaryComponent } from '../components/order-summary/order-summary.component';
import { OrdersComponent } from '../components/orders/orders.component';
import { ProductCardComponent } from '../components/product-card/product-card.component';
import { ProductGridComponent } from '../components/product-grid/product-grid.component';
import { ProductSliderComponent } from '../components/product-slider/product-slider.component';
import { ReccommandetionGridComponent } from '../components/recommandetion-grid/recommandetion-grid.component';
import { RegisterNowComponent } from '../components/register-now/register-now.component';
import { ReturnOrderListingComponent } from '../components/return-order-listing/return-order-listing.component';
import { ReturnOrderMobileFilterComponent } from '../components/return-order-mobile-filter/return-order-mobile-filter.component';
import { ReturnOrderRequestComponent } from '../components/return-order-request/return-order-request.component';
import { RFQCartComponent } from '../components/rfq-cart/rfq-cart.component';
import { RFQConfirmationComponent } from '../components/rfq-confirmation/rfq-confirmation.component';
import { SearchBarComponent } from '../components/search-bar/search-bar.component';
import { SelectVariantComponent } from '../components/select-variant/select-variant.component';
import { SortByComponent } from '../components/sort-by/sort-by.component';
import { ToastMsgComponent } from '../components/toast-msg/toast-msg.component';
import { TwoFactorAuthenticationComponent } from '../components/two-factor-authentication/two-factor-authentication.component';
import { WishlistFilterMobileComponent } from '../components/wishlist-filter-mobile/wishlist-filter-mobile.component';
import { CartModalPage } from '../pages/cart-modal/cart-modal.page';
import { AddReviewComponent } from './../components/add-review/add-review.component';
import { EditProfileComponent } from './../components/edit-profile/edit-profile.component';
import { SettingComponent } from './../components/setting/setting.component';
import { ProductReviewPage } from './../pages/product-review/product-review.page';
import { ClickOutsideDirective } from './click-outside.directive';


const NZ_MODULES = [
	NzRateModule,
];

const COMPONENTS = [
	// ModalComponentComponent,
	FilterComponent,
	MenusComponent,
	HeaderComponent,
	CartHeaderComponent,
	RegisterNowComponent,
	BannerComponent,
	NotFoundComponent,
	FooterComponent,
	OrdersComponent,
	MyQuoteRequestComponent,
	OrderSampleComponent,
	MyAccountMenuComponent,
	AddressComponent,
	AccountInformationComponent,
	SearchBarComponent,
	ProductSliderComponent,
	MyAccountComponent,
	ChangePasswordComponent,
	ForgotPasswordComponent,
	OfferDialogComponent,
	ProductGridComponent,
	ReccommandetionGridComponent,
	ExploreCatelogueComponent,
	HomeTitleComponent,
	CommonButtonComponent,
	ProductCardComponent,
	TwoFactorAuthenticationComponent,
	OrderSummaryComponent,
	CartModalPage,
	HorizontalWishlistComponent,
	NotificationComponent,
	NotificationCategoryComponent,
	AddReviewComponent,
	RFQConfirmationComponent,
	RFQCartComponent,
	OrderDetailsComponent,
	CancelProductComponent,
	ClickOutsideDirective,
	LoaderComponent,
	CategoriesComponent,
	SettingComponent,
	ProductReviewPage,
	SortByComponent,
	LimitTo, // directive
	ReturnOrderListingComponent,
	ReturnOrderRequestComponent,
	LoginPropmptComponent,
	DesignAndColorComponent,
	ReturnOrderMobileFilterComponent,
	NotifyMeComponent,
	EditProfileComponent,
	NoInternetComponent,
	SelectVariantComponent,
	ToastMsgComponent,
	WishlistFilterMobileComponent,
	LandingScreenComponent,
];


const MODULES = [
	CommonModule,
	IonicModule,
	TranslateModule,
	RouterModule,
	FormsModule,
	ReactiveFormsModule,
	CodeInputModule,
	PdfJsViewerModule,
	// AgmCoreModule.forRoot({
	// 	apiKey: 'AIzaSyDHvKG_qCt_sT-rYBPzzIvCYy3BndUs8Ro',
	// 	libraries: ['places']
	// }),
	NgxPaginationModule,
];

const PIPES = [
];
@NgModule({
	imports: [
		...MODULES,
		...NZ_MODULES
	],
	declarations: [...COMPONENTS, ...PIPES, CommonPipe],
	exports: [
		...COMPONENTS,
		...MODULES,
		...NZ_MODULES,
		...PIPES,
		CommonPipe
	],
	providers: [
		NativeGeocoder,
		DatePipe,
	],
	schemas: [
		CUSTOM_ELEMENTS_SCHEMA
	]
})
export class SharedModule { }

<ion-header class="return-order-header">
	<ion-toolbar mode="md">
		<img src="./assets/icon/leftArrow.svg" alt="back-arrow" slot="start" (click)="goBack()"
			[ngClass]="commonService.domDirectionLTR ? '' : 'rotate-arrow'">
		<ion-title mode="ios">
			{{ isCancelPage ? ("returnOrderListing.cancelOrder" | translate):("returnOrderListing.title" | translate)}}
		</ion-title>
		<div class="section-block header-filter-block" slot="end" (click)="openFilterByModal()">
			<div class="filter-icon">
				<img src="./assets/icon/filterIcon.svg" alt="juman">
			</div>
			<div class="filter-label" [ngClass]="showFilter ? 'close-label' : ''">{{"filters"
				| translate}}
			</div>
		</div>
	</ion-toolbar>
</ion-header>
<div class="searchbar-container">
	<!-- <app-search-bar></app-search-bar> -->
</div>
<div class="return-orders-listing">
	<div class="listing-header" *ngIf="showHeader">
		<h1 class="title-div m-0">
			{{ isCancelPage ? ("returnOrderListing.cancelOrder" | translate):("returnOrderListing.title" | translate)}}
		</h1>
		<div *ngIf="false">
			<button class="listing-header-btn" (click)="goToReturnRequest()">{{"returnOrderListing.returnReq" |
				translate}}</button>
		</div>
		<div class="search-and-filter search-and-filter-return-order">
			<div class="search-and-filter" *ngIf="showHeader">
				<div class="filter-div">
					<div class="printer-icon">
						<img src="./assets/icon/fi_printer.svg" (click)="downloadExcel()" title="{{'export'|translate}}"
							alt="juman">
					</div>
					<div class="search-div">
						<ion-item lines="none">
							<ion-input type="text" placeholder="{{'search' | translate}}" [(ngModel)]="searchText"
								[ngModelOptions]="{standalone: true}" (keyup.enter)="search()"></ion-input>
							<img src="./assets/icon/close.svg" class="input-clear-icon" slot="end" *ngIf="searchText"
								(click)="clearSearch()" alt="juman">
						</ion-item>
					</div>
					<div class="search-icon mr-40 ">
						<img src="./assets/icon/search.svg" (click)="search()" alt="juman">
					</div>
					<div class="section-block">
						<div class="filter-icon" (click)="showFilter = !showFilter" *ngIf="!showFilter">
							<img src="./assets/icon/filterIcon.svg" alt="juman">
						</div>
						<div class="close-filter-icon" (click)="showFilter = !showFilter" *ngIf="showFilter">
							<img src="assets/icon/modal-close-mobile.svg" alt="juman">
						</div>
						<div class="filter-label" [ngClass]="showFilter ? 'close-label' : ''"
							(click)="showFilter = !showFilter">{{"filters"
							| translate}}
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
	<div *ngIf="showHeader">

		<!-- <form [formGroup]="dateRange" class="mb-10" *ngIf="showFilter">
			<div class="filter-section">
				<div class="filters">
					<div class="input-div-container">
						<div class="status-dropdown-div">
							<ion-list class="status-dropdown" mode="md">
								<ion-item class="ion-card " lines="none">
									<ion-select cancelText="{{'Cancel'|translate}}" okText="{{'Ok' | translate}}"
										[ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'" interface="popover"
										value="all" [interfaceOptions]="selectInterface" [(ngModel)]="selectStatus"
										placeholder="{{ 'select' | translate }}" [ngModelOptions]="{standalone: true}">
										<ion-select-option [value]="data.value"
											*ngFor="let data of statusList">{{data.label
											| translate}}</ion-select-option>
									</ion-select>
								</ion-item>
							</ion-list>
						</div>
					</div>

					<div class="from-date-div">
						<ion-label class="from-date-label"
							[ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'">{{"from"
							| translate}}</ion-label>
						<ion-item class="from-date-class" [ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'"
							id="fromDateId">
							<ion-input value="{{ fromDate | date: 'longDate' }}" class="ion-text-start"></ion-input>
							<ion-icon src="assets/icon/fi_calendar.svg" class="ion-text-end" alt="juman"></ion-icon>
							<ion-popover trigger="fromDateId" size="cover" id="datePopover">
								<ng-template>
									<ion-datetime presentation="date" [min]="'2000-01-01'"
										[max]="toDate ? toDate : maxFromDate" [(ngModel)]="fromDate"
										formControlName="fromDate" #fromdate (ionChange)="onDateChange(fromdate)"><span
											slot="title">{{"from"
											| translate}}</span></ion-datetime>
								</ng-template>
							</ion-popover>
						</ion-item>
					</div>
					<div class="to-date-div">
						<ion-label class="to-date-label"
							[ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'">{{"to"
							| translate}}</ion-label>
						<ion-item class="to-date-class" [ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'"
							id="toDateId">
							<ion-input value="{{ toDate | date: 'longDate' }}" class="ion-text-start"></ion-input>
							<ion-icon src="assets/icon/fi_calendar.svg" class="ion-text-end" alt="juman"></ion-icon>
							<ion-popover trigger="toDateId" size="cover" id="datePopover">
								<ng-template>
									<ion-datetime presentation="date" [min]="fromDate ? fromDate : '2000-01-01'"
										[max]="maxDate" [(ngModel)]="toDate" formControlName="toDate" #todate
										(ionChange)="onDateChange(todate)"><span slot="title">{{"to"
											| translate}}</span></ion-datetime>
								</ng-template>
							</ion-popover>
						</ion-item>
					</div>

				</div>
				<div class="filter-buttons">
					<div class="apply-button ion-text-end">
						<button class="filter-apply-button" (click)="clearFilter()">{{"clear" | translate}}</button>
					</div>
					<button class="filter-apply-button" (click)="applyBtn()">{{"apply" | translate}}</button>
				</div>

			</div>
		</form> -->
		<form [formGroup]="dateRange" *ngIf="showFilter">
			<div class="filter-section">
				<div class="filter-header mw-250">
					<div class="status-label">
						<ion-label [ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'">{{"status"
							| translate}}</ion-label>
					</div>
					<div class="input-div-container">
						<div class="status-dropdown-div">
							<ion-list class="status-dropdown" mode="md">
								<ion-item class="ion-card " lines="none">
									<ion-select cancelText="{{'Cancel'|translate}}" okText="{{'Ok' | translate}}"
										[ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'" interface="popover"
										value="all" [interfaceOptions]="selectInterface" [(ngModel)]="selectStatus"
										placeholder="{{ 'select' | translate }}" [ngModelOptions]="{standalone: true}"
										label-placement="stacked" mode="md" fill="">
										<ion-select-option [value]="data.value"
											*ngFor="let data of statusList">{{data.label
											| translate}}</ion-select-option>
									</ion-select>
								</ion-item>
							</ion-list>
						</div>
					</div>
				</div>
				<div class="filter-header">
					<div class="status-label">
						<ion-label [ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'">{{'orderDate' |
							translate}}</ion-label>
					</div>
					<div class="input-div-container">
						<div class="from-date-div">
							<ion-item class="from-date-class" [ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'"
								id="fromDateId">
								<ion-input value="{{ fromDate | date: 'MMM dd, YYYY'}}" placeholder="{{'from'
			                                | translate}}" class="ion-text-start"></ion-input>
								<ion-icon src="assets/icon/fi_calendar.svg" class="ion-text-end" alt="juman"></ion-icon>

								<ion-popover trigger="fromDateId" size="cover" id="datePopover">
									<ng-template>
										<ion-datetime presentation="date" [min]="'2000-01-01'"
											[max]="toDate ? toDate : maxFromDate" [(ngModel)]="fromDate"
											formControlName="fromDate" #fromdate
											(ionChange)="onDateChange(fromdate)"><span slot="title">{{"from"
												| translate}}</span></ion-datetime>
									</ng-template>
								</ion-popover>

							</ion-item>
						</div>
					</div>
				</div>
				<div class="filter-header">
					<div class="status-label">
						<ion-label [ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'">{{'toDate' |
							translate}}</ion-label>
					</div>
					<div class="input-div-container">
						<div class="to-date-div">
							<ion-item class="to-date-class" [ngClass]="commonService.domDirectionLTR ? 'LTR' : 'RTL'"
								id="toDateId">
								<ion-input value="{{ toDate | date: 'MMM dd, YYYY' }}" placeholder="{{'to'
			                                | translate}}" class="ion-text-start"></ion-input>
								<ion-icon src="assets/icon/fi_calendar.svg" class="ion-text-end" alt="juman"></ion-icon>

								<ion-popover trigger="toDateId" size="cover" id="datePopover">
									<ng-template>
										<ion-datetime presentation="date" [min]="fromDate ? fromDate : '2000-01-01'"
											[max]="maxDate" [(ngModel)]="toDate" formControlName="toDate" #todate
											(ionChange)="onDateChange(todate)"><span slot="title">{{"to"
												| translate}}</span></ion-datetime>
									</ng-template>
								</ion-popover>

							</ion-item>
						</div>
					</div>
				</div>

				<div class="filter-buttons">
					<div class="apply-button ion-text-end">
						<button class="filter-apply-button" (click)="clearFilter()">{{"clear" | translate}}</button>
					</div>
					<button class="filter-apply-button" (click)="applyBtn()">{{"apply" | translate}}</button>
				</div>

			</div>
		</form>
		<div class="listing-table">
			<app-loader *ngIf="isLoading"></app-loader>

			<table class="table" *ngIf="!isLoading">
				<thead>
					<th class="column-heading mw-100 text-left">
						{{"returnOrderListing.request"
						| translate}}</th>
					<th class="column-heading mw-100 text-left">
						{{"returnOrderListing.order_id"
						| translate}}</th>
					<th class="column-heading mw-150 text-left">
						{{"returnOrderListing.requestDate"
						| translate}}</th>
					<th class="column-heading mw-200 text-left">
						{{"returnOrderListing.productName"
						| translate}}</th>
					<th class="column-heading mw-310 text-left" *ngIf="!isCancelPage">
						{{"returnOrderListing.pickUpForm"
						| translate}}</th>
					<th class="column-heading mw-180 text-left">{{"status"|
						translate}}</th>
				</thead>
				<tbody class="table-body">
					<tr *ngFor="let order of orderList;index as i" class="table-row"
						(click)="gotoOrderDetails(order.invoice_id , order.order_id)">
						<td class="text-left">
							{{order.invoice_id}}</td>
						<td class="text-left">
							{{order.order_id}}</td>
						<td class="text-left">
							{{order.request_date | date : 'MMM dd, YYYY'}}
						</td>
						<td class="text-left">
							{{order.product_name}}
						</td>
						<td *ngIf="!isCancelPage" class="text-left">
							{{order.pickup_from}}
						</td>
						<td>
							<div class="status-container">
								<ion-button fill="clear" class="status-btn"
									[ngClass]="{'under-review' : order.order_status == 'CANCELLATION_REQUESTED','returned':order.order_status == 'RETURN_REQUESTED','reject':order.order_status == 'REJECTED',
                                'reject':order.order_status != 'REJECTED' ||  order.order_status != 'CANCELLATION_REQUESTED' || order.order_status != 'RETURN_REQUESTED'}">
									{{displayStatus(order.order_status) | translate}}
								</ion-button>
								<div class="image-container"
									[ngClass]="commonService.domDirectionLTR ? '' : 'rotate-arrow'">
									<img src="./assets/icon/rightArrow.svg" alt="righta-arrow" alt="juman">
								</div>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
			<div class="no-record width-100" *ngIf="!orderList.length">
				{{'noOrdersYet' | translate}}
			</div>
		</div>


	</div>
	<div class="pagination-wrapper">
		<ion-row class="pagination-div">
			<ion-col size="4" class="perpage-col">
				<ion-list class="perpage-dropdown">
					<ion-item class="ion-card" lines="none">
						<ion-select cancelText="{{'Cancel'|translate}}" okText="{{'Ok' | translate}}"
							interface="popover" value="10" [interfaceOptions]="perPageInterface">
							<ion-select-option value="10">10</ion-select-option>
							<ion-select-option value="20">20</ion-select-option>
						</ion-select>
					</ion-item>
					<ion-label class="perpage-title">{{"per_page" | translate}}</ion-label>
				</ion-list>
			</ion-col>
			<ion-col size="8" class="pagination-col return-orders-pagination-col">
				<app-tile class="grid-tile" *ngFor="let post of orderList | paginate : 
        { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: count }; 
        let i = index" [current]="post"></app-tile>
				<pagination-controls class="paginator"
					[ngClass]="commonService.domDirectionLTR ? '' : 'paginator-icon-rotate'"
					previousLabel="{{'prev' | translate}}" nextLabel="{{'next' | translate}}"
					(pageChange)="onChange($event)"></pagination-controls>
			</ion-col>
		</ion-row>
	</div>
</div>
<ion-content class="return-orders-listing return-order-content" *ngIf="!getPlatformService.isDesktop">
	<div class="mobile-view-listing">
		<div class="order-container">
			<ion-grid class="mobile-listing-grid" *ngIf="orderList.length">
				<ion-row class="order-card" *ngFor="let order of orderList;index as i">
					<ion-col size="12">
						<ion-grid>
							<ion-row (click)="gotoOrderDetailsFromMobile(order.invoice_id , order.order_no)">
								<ion-col size="6" class="order-card-col">
									<div class="grid-label">{{"returnOrderListing.request" | translate}}</div>
									<div class="grid-values">{{order.invoice_id}}</div>
								</ion-col>
								<ion-col size="6" class="order-card-col">
									<div class="grid-label">{{"returnOrderListing.order_id" | translate}}</div>
									<div class="grid-values">{{order.order_id}}</div>
								</ion-col>
								<ion-col size="6" class="order-card-col">
									<div class="grid-label">{{"returnOrderListing.requestDate" | translate}}
									</div>
									<div class="grid-values">{{order.request_date | date : 'MMM dd, YYYY'}}</div>
								</ion-col>
								<ion-col size="6" class="order-card-col">
									<div class="grid-values">
										<ion-button fill="clear" class="status-btn"
											[ngClass]="{'under-review' : order.order_status == 'CANCELLATION_REQUESTED','returned':order.order_status == 'RETURN_REQUESTED','reject':order.order_status == 'REJECTED',
                                                                    'reject':order.order_status != 'REJECTED' ||  order.order_status != 'CANCELLATION_REQUESTED' || order.order_status != 'RETURN_REQUESTED'}">
											{{displayStatus(order.order_status) | translate}}
										</ion-button>
									</div>
								</ion-col>
								<ion-col size="12" *ngIf="!isCancelPage" class="order-card-col">
									<div class="grid-label">{{"returnOrderListing.pickUpForm" | translate}}</div>
									<div class="grid-values pick-up">{{order.pickup_from}}</div>
								</ion-col>
							</ion-row>
						</ion-grid>
					</ion-col>
				</ion-row>
				<ion-infinite-scroll threshold="400px" (ionInfinite)="loadData($event)">
					<ion-infinite-scroll-content *ngIf="enableNext" loadingSpinner="circular"
						loadingText="{{'loadMoreOrders' | translate}}">
					</ion-infinite-scroll-content>
				</ion-infinite-scroll>
			</ion-grid>
			<div *ngIf="!orderList.length" class="no-orders">
				<img src="./assets/icon/no-order.svg" alt="Juman" class="mb-30" />
				<div class="mb-20">
					<p class="label-3 color-app-black ">{{'noOrdersYet' | translate}}</p>
				</div>
			</div>
		</div>
	</div>


</ion-content>
<ion-footer class="pt-10 return-order-footer" *ngIf="false">
	<div class="footer-button-container return-order-listing-footer">
		<div class="footer-button">
			<button class="listing-footer-btn" (click)="goToRequest()">{{"returnOrderListing.returnReq" |
				translate}}</button>
		</div>
	</div>
</ion-footer>
<ion-header>
	<ion-header>
		<ion-toolbar mode="md">
			<div class="registration-desktop-header ion-text-center" *ngIf="getPlatformService.isDesktop">
				{{ 'Create account' | translate }}
			</div>
			<div class="mobile-back ion-text-start ml-10" (click)="closeModal()" *ngIf="!getPlatformService.isDesktop">
				<img src="assets/icon/leftArrow.svg" alt="leftArrow">
			</div>
			<div class=" text-right" [ngClass]="commonService.domDirectionLTR ? 'modal-close' : 'modal-close-rtl'"
				(click)="closeModal()" *ngIf="getPlatformService.isDesktop">
				<img src="assets/icon/close.svg" alt="Close" height="100%" width="100%">
			</div>
		</ion-toolbar>
	</ion-header>
</ion-header>

<app-loader *ngIf="isLoading && !getPlatformService.isDesktop "></app-loader>

<ion-content>
	<div class="registration-container"
		[ngClass]="[ getPlatformService.isDesktop ? 'registration-container-desktop p-top-0': '']">
		<div class="registration-header" *ngIf="!getPlatformService.isDesktop">
			{{ 'Register to Juman' | translate }}
		</div>
		<form [formGroup]="registerFromGroup">

			<div class="account-info" [ngClass]="getPlatformService.isDesktop ? 'p-top-0':'pt-25'">
				<div class="account-info-head">
					{{ 'account_details' | translate }}
				</div>
				<div class="name common-input">
					<ion-item lines="none" mode="md">
						<img slot="start" *ngIf="!getPlatformService.isDesktop" src="assets/icon/name-icon.svg"
							alt="Juman">
						<ion-label position="floating" *ngIf="getPlatformService.isDesktop">{{ 'NAME' | translate }}<sup
								class="pl-5 required-star">*</sup>
						</ion-label>
						<ion-label position="floating" *ngIf="!getPlatformService.isDesktop">{{ 'Name' | translate
							}}<sup class="pl-5 required-star">*</sup>
						</ion-label>
						<ion-input maxlength="50" formControlName="name"></ion-input>
					</ion-item>
					<ng-container
						*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'name', 'formName': registerFromGroup} }">
					</ng-container>
				</div>
				<div class="email common-input">
					<ion-item lines="none" mode="md">
						<img slot="start" *ngIf="!getPlatformService.isDesktop" src="assets/icon/email-icon.svg"
							alt="Juman">
						<ion-label position="floating" *ngIf="getPlatformService.isDesktop">{{ 'EMAIL' | translate
							}}<sup class="pl-5 required-star">*</sup></ion-label>
						<ion-label position="floating" *ngIf="!getPlatformService.isDesktop">{{ 'Email' | translate
							}}<sup class="pl-5 required-star">*</sup></ion-label>
						<ion-input formControlName="email"></ion-input>
					</ion-item>
					<ng-container
						*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'email', 'formName': registerFromGroup} }">
					</ng-container>
				</div>
				<div class="name common-input">
					<ion-item class="mobile-phone" lines="none" mode="md">
						<img slot="start" *ngIf="!getPlatformService.isDesktop" src="assets/icon/mobile_phone_icon.svg"
							alt="Juman">
						<!-- <label *ngIf="countryCodeLabel" class="countrycode-label" slot="start">+966</label> -->
						<ion-label position="floating" *ngIf="getPlatformService.isDesktop">{{ 'MOBILE_PHONE' |
							translate }}<sup class="pl-5 required-star">*</sup></ion-label>
						<ion-label position="floating" *ngIf="!getPlatformService.isDesktop">{{ 'mobile_phone' |
							translate }}<sup class="pl-5 required-star">*</sup></ion-label>
						<ion-input maxlength="9" inputmode="numeric"
							[ngClass]="commonService.domDirectionLTR ? 'mobilephone-input' : 'mobilephone-input-rtl'"
							formControlName="mobilephone" (ionFocus)="onMobilePhoneFocus()"
							(keypress)="phoneClick($event)" (ionBlur)="onMobilePhoneBlur()">
							<label for="" *ngIf="countryCodeLabel" class="country-code">
								+966
							</label>
						</ion-input>
					</ion-item>
					<ng-container
						*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'mobilephone', 'formName': registerFromGroup} }">
					</ng-container>
				</div>
				<div class="password common-input">
					<ion-icon name="eye-outline" slot="end" *ngIf="passwordType == 'text'"
						(click)="passwordType = 'password'"
						[ngClass]="[ commonService.domDirectionLTR ? 'password-icon' : 'password-icon-arabic' ]"></ion-icon>
					<ion-icon name="eye-Off-outline" slot="end" *ngIf="passwordType == 'password'"
						(click)="passwordType = 'text'"
						[ngClass]="[ commonService.domDirectionLTR ? 'password-icon' : 'password-icon-arabic' ]"></ion-icon>
					<ion-item lines="none" mode="md">
						<img slot="start" *ngIf="!getPlatformService.isDesktop" src="assets/icon/password-icon.svg"
							alt="Juman">
						<ion-label position="floating" *ngIf="getPlatformService.isDesktop">{{ 'PASSWORD' |
							translate }}<sup class="pl-5 required-star">*</sup></ion-label>
						<ion-label position="floating" *ngIf="!getPlatformService.isDesktop">{{ 'Password' |
							translate }}<sup class="pl-5 required-star">*</sup></ion-label>
						<ion-input type="{{ passwordType }}" class="registration-pass" clearOnEdit="false"
							formControlName="password" (keydown.space)="$event.preventDefault();"></ion-input>
					</ion-item>
					<ng-container
						*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'password', 'formName': registerFromGroup} }">
					</ng-container>
				</div>
				<div class="repeat-password common-input">
					<ion-icon name="eye-outline" slot="end" *ngIf="repeatPasswordType == 'text'"
						(click)="repeatPasswordType = 'password'"
						[ngClass]="[ commonService.domDirectionLTR ? 'password-icon' : 'password-icon-arabic' ]"></ion-icon>
					<ion-icon name="eye-Off-outline" slot="end" *ngIf="repeatPasswordType == 'password'"
						(click)="repeatPasswordType = 'text'"
						[ngClass]="[ commonService.domDirectionLTR ? 'password-icon' : 'password-icon-arabic' ]"></ion-icon>
					<ion-item lines="none" mode="md">
						<img slot="start" *ngIf="!getPlatformService.isDesktop" src="assets/icon/password-icon.svg"
							alt="Juman">
						<ion-label position="floating" *ngIf="getPlatformService.isDesktop">{{ 'REPEAT_PASSWORD' |
							translate }}<sup class="pl-5 required-star">*</sup>
						</ion-label>
						<ion-label position="floating" *ngIf="!getPlatformService.isDesktop">{{ 'repeat_password' |
							translate }}<sup class="pl-5 required-star">*</sup>
						</ion-label>
						<ion-input class="registration-pass" type="{{ repeatPasswordType }}" clearOnEdit="false"
							formControlName="repeatPassword" (keydown.space)="$event.preventDefault();">
						</ion-input>
					</ion-item>
					<ng-container
						*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'repeatPassword', 'formName': registerFromGroup} }">
					</ng-container>
				</div>
			</div>
			<div class="company-details">
				<div class="company-details-head">
					{{ 'company_details' | translate }}
				</div>
				<div class="form-inputs common-input company-field">
					<ion-item lines="none" mode="md">
						<ion-label position="floating"
							[ngClass]="{'item-has-value' : companyCRNoName}">{{'companyDetailsComponent.companyCRNoLabelFile'
							| translate}}<sup class="pl-5 required-star">*</sup></ion-label>
						<input type="file" accept="application/octet-stream" class="file-input"
							[ngClass]="{'position-absolute':companyCRNoName}"
							(change)="upload($event,'company-cr-number')" (click)="$event.stopPropagation()" #crNumber>
						<ion-input *ngIf="!companyCRNoName" formControlName="companyCRNoName" readonly
							class="hidden-input"></ion-input>
						<ion-button type="primary" slot="end" class="upload-btn" *ngIf="!companyCRNoName"
							(click)="crNumber.click()">
							{{ 'upload' | translate}}
						</ion-button>
						<ion-button slot="end" class="remove-btn" *ngIf="companyCRNoName"
							(click)="remove($event,'company-cr-number')">
							{{ 'remove' | translate}}
						</ion-button>
						<p class="file-name" *ngIf="companyCRNoName" #companyCrNoElem>{{companyCRNoName}}</p>
					</ion-item>
					<div [ngClass]="commonService.domDirectionLTR ? 'more-info-tooltip' : 'more-info-tooltip-arabic'"
						*ngIf="getPlatformService.isDesktop"><img src="./assets/icon/more_info.svg" alt="Juman">
						<span
							class="more-info-tooltiptext more-info-file-tooltip">{{'companyDetailsComponent.fileTooltipError'|
							translate}}</span>
					</div>
					<div class="tooltip company-cr-no-tooltip" *ngIf="companyCRNoName"><span
							class="company-span">.</span>
						<span class="tooltiptext">{{companyCRNoName}}</span>
					</div>
					<ng-container *ngIf="isCompanyCRNoFile">
						<ion-text color="danger" class="error-msg">
							{{'companyDetailsComponent.companyCRNoNameError'| translate}}
						</ion-text>
					</ng-container>
				</div>
				<div class="form-inputs common-input">
					<ion-item lines="none" mode="md">
						<ion-label position="floating">{{'companyDetailsComponent.companyCRNoLabel' | translate }}<sup
								class="pl-5 required-star">*</sup></ion-label>
						<ion-input maxlength="10" type="tel" (keypress)="phoneClick($event)" inputmode="numeric"
							formControlName="crNumber"></ion-input>
					</ion-item>
					<ng-container
						*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'crNumber', 'formName': registerFromGroup} }">
					</ng-container>
				</div>
				<div class="form-inputs common-input company-field">
					<ion-item lines="none" mode="md">
						<ion-label position="floating"
							[ngClass]="{'item-has-value' : vatCertificateNoName}">{{'companyDetailsComponent.vatCertificateLabelFile'
							| translate}}<sup class="pl-5 required-star">*</sup></ion-label>
						<input type="file" accept="application/octet-stream" class="file-input"
							[ngClass]="{'position-absolute':vatCertificateNoName}"
							(change)="upload($event,'vat-certificate-no')" (click)="$event.stopPropagation()"
							#vatCertificateNo>
						<ion-input *ngIf="!vatCertificateNoName" readonly formControlName="vatCertificateNoName"
							class="hidden-input"></ion-input>
						<ion-button type="primary" slot="end" class="upload-btn" *ngIf="!vatCertificateNoName"
							(click)="vatCertificateNo.click()">
							{{ 'upload' | translate}}
						</ion-button>
						<ion-button slot="end" class="remove-btn" *ngIf="vatCertificateNoName"
							(click)="remove($event,'vat-certificate-no')">
							{{ 'remove' | translate}}
						</ion-button>
						<p class="file-name" *ngIf="vatCertificateNoName" #vatCertificateNoElem>{{vatCertificateNoName}}
						</p>
					</ion-item>
					<div [ngClass]="commonService.domDirectionLTR ? 'more-info-tooltip' : 'more-info-tooltip-arabic'"
						*ngIf="getPlatformService.isDesktop"><img src="./assets/icon/more_info.svg" alt="Juman">
						<span
							class="more-info-tooltiptext more-info-file-tooltip">{{'companyDetailsComponent.fileTooltipError'|
							translate}}</span>
					</div>
					<div class="tooltip vat-certificate-no-tooltip" *ngIf="vatCertificateNoName"><span
							class="company-span">.</span>
						<span class="tooltiptext">{{vatCertificateNoName}}</span>
					</div>
					<ng-container *ngIf="isVatCertificateNoFile">
						<ion-text color="danger" class="error-msg">
							{{'companyDetailsComponent.vatCertificateNoError'| translate}}
						</ion-text>
					</ng-container>
				</div>
				<div class="form-inputs common-input">
					<ion-item lines="none" mode="md">
						<ion-label position="floating">{{'companyDetailsComponent.vatCertificateLabel' | translate
							}}<sup class="pl-5 required-star">*</sup></ion-label>
						<ion-input maxlength="15" type="tel" (keypress)="phoneClick($event)" inputmode="numeric"
							formControlName="vatNumber"></ion-input>
					</ion-item>
					<ng-container
						*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'vatNumber', 'formName': registerFromGroup} }">
					</ng-container>
				</div>
				<div class="form-inputs common-input company-field">
					<ion-item lines="none" mode="md">
						<ion-label position="floating"
							[ngClass]="{'item-has-value' : nationalAddressFile}">{{'companyDetailsComponent.nationalAddressLabelFile'
							| translate}}<sup class="pl-5 required-star">*</sup></ion-label>
						<input type="file" accept="application/octet-stream" class="file-input"
							[ngClass]="{'position-absolute':nationalAddressFile}"
							(change)="upload($event,'national-address-file')" (click)="$event.stopPropagation()"
							#nationalAddress>
						<ion-input *ngIf="!nationalAddressFile" readonly formControlName="nationalAddressFile"
							class="hidden-input"></ion-input>
						<ion-button type="primary" slot="end" class="upload-btn" *ngIf="!nationalAddressFile"
							(click)="nationalAddress.click()">
							{{ 'upload' | translate}}
						</ion-button>
						<ion-button slot="end" class="remove-btn" *ngIf="nationalAddressFile"
							(click)="remove($event,'national-address-file')">
							{{ 'remove' | translate}}
						</ion-button>
						<p class="file-name" *ngIf="nationalAddressFile" #nationalAddressFileElem>
							{{nationalAddressFile}}</p>
					</ion-item>
					<div [ngClass]="commonService.domDirectionLTR ? 'more-info-tooltip' : 'more-info-tooltip-arabic'"
						*ngIf="getPlatformService.isDesktop"><img src="./assets/icon/more_info.svg" alt="Juman">
						<span
							class="more-info-tooltiptext more-info-file-tooltip">{{'companyDetailsComponent.fileTooltipError'|
							translate}}</span>
					</div>
					<div class="tooltip national-address-file-tooltip" *ngIf="nationalAddressFile"><span
							class="company-span">.</span>
						<span class="tooltiptext">{{nationalAddressFile}}</span>
					</div>
					<ng-container *ngIf="isNationalAddressFile">
						<ion-text color="danger" class="error-msg">
							{{'companyDetailsComponent.nationalAddressFileError'| translate}}
						</ion-text>
					</ng-container>
				</div>
				<div class="form-inputs common-input">
					<ion-item lines="none" mode="md">
						<ion-label position="floating">{{'companyDetailsComponent.nationalId' | translate
							}}<sup class="pl-5 required-star">*</sup></ion-label>
						<ion-input maxlength="10" type="tel" (keypress)="phoneClick($event)" inputmode="numeric"
							formControlName="nationalId"></ion-input>
					</ion-item>
					<ng-container
						*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'nationalId', 'formName': registerFromGroup} }">
					</ng-container>
				</div>
			</div>
			<div class="company-details">
				<div class="company-details-head">
					{{ 'location' | translate }}<sup class="asterisk-class">*</sup>
				</div>
				<div class="company-registration common-input">
					<ion-item lines="none" mode="md" class="custom-ion-item">
						<img slot="start" class="pl-14 mob-mr-0" *ngIf="!getPlatformService.isDesktop"
							src="assets/icon/location-icon.svg" alt="Juman">
						<ion-label class="ph-16" position="floating"
							*ngIf="getPlatformService.isDesktop && isLocationSelected">{{
							'LOCATION' |
							translate }}</ion-label>
						<ion-label class="ph-16" position="floating"
							[ngClass]="[ isLocationSelected ? 'floating-location-label' : '']"
							*ngIf="!getPlatformService.isDesktop && isLocationSelected">{{
							'selectLocation' |
							translate }}</ion-label>
						<ion-select cancelText="{{'Cancel'|translate}}" okText="{{'Ok' | translate}}"
							[ngClass]="[ isLocationSelected ? 'location-selected' : 'location-select']"
							[interface]="getPlatformService.isDesktop ? 'popover' : 'alert'" mode="md" fill=""
							label-placement="stacked" placeholder="{{'LOCATION' | translate }}"
							[interfaceOptions]="locationSelect" formControlName="location"
							(ionChange)="locationSelected($event)">
							<ion-select-option *ngFor="let data of locationData" [value]="data.city_id">{{
								commonService.domDirectionLTR ? data.city_en : data.city_ar
								}}</ion-select-option>
						</ion-select>
					</ion-item>
					<ng-container
						*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'location', 'formName': registerFromGroup} }">
					</ng-container>
				</div>
				<!-- code required -->
				<!-- <div class="company-registration-info">
                    Lorem ipsum dolor sit amet adipiscing consect.
                </div> -->
				<!-- <div class="google-maps-class">
                    <agm-map class="google-maps" [latitude]="19.0760" [longitude]="72.8777" [zoom]="15"
                        [disableDefaultUI]="false">
                        <agm-marker [latitude]="24.7136" [longitude]="46.6753" [markerDraggable]=true></agm-marker>
                    </agm-map>
                </div> -->
				<div class="select-intrest-container">
					<div class="select-intrest-head">
						{{ 'Select interests' | translate }}<sup class="asterisk-class">*</sup>
					</div>
					<div class="select-intrest">
						<ion-list>
							<ion-item *ngFor="let item of registrationCheckbox" lines="none">
								<ion-label class="checkbox-labels"
									[ngClass]="getPlatformService.isDesktop ? 'p-5':'p-0'">
									{{ commonService.domDirectionLTR ? item.label : item.label_ar }}</ion-label>
								<ion-checkbox mode="md" slot="start" [value]="item.value"
									(ionChange)="selectCheckbox(item)" [checked]="item.isChecked"
									formControlName="selectIntrestList"></ion-checkbox>
							</ion-item>
						</ion-list>
						<ng-container
							*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'selectIntrestList', 'formName': registerFromGroup} }">
						</ng-container>
					</div>


				</div>


			</div>
		</form>

		<div class="bottom-container">

			<div class="select-intrest p-0">
				<ion-list>
					<ion-item lines="none">
						<ion-label class="checkbox-labels2" [ngClass]="getPlatformService.isDesktop ? 'p-5':'p-0'">
							{{'agree' | translate }} <span class="terms-and-condition" (click)="gotoTermsConditions()">
								{{'termsCondition' | translate}}</span>
						</ion-label>
						<ion-checkbox mode="md" slot="start" [(ngModel)]="termsCheck"
							[checked]="termsCheck"></ion-checkbox>
					</ion-item>
				</ion-list>
			</div>

			<div class="register-bottom-container" *ngIf="getPlatformService.isDesktop">
				<div class="cancel pr-40" (click)="cancelModal()">
					{{ 'Cancel' | translate }}
				</div>
				<div class="register-button">
					<ion-button (click)="register()" [disabled]="isLoading">
						{{ 'Register' | translate }}
					</ion-button>
				</div>
			</div>
		</div>


	</div>
	<ng-template let-formObject #validationMessages>
		<ng-container *ngFor="let validation of validation_messages[formObject.controlName]">
			<ion-text color="danger" class="error-msg"
				*ngIf="formObject.formName.controls[formObject.controlName].hasError(validation.type) && formObject.formName.controls[formObject.controlName].dirty">
				{{validation.message | translate}}
			</ion-text>
		</ng-container>
	</ng-template>
</ion-content>

<ion-footer *ngIf="!getPlatformService.isDesktop">
	<div class="register-button ion-text-center mh-10">
		<app-common-button color="#FFFFFF" background="#38C2D4" title="{{'Register'|translate}}" [isIcon]="false"
			[isHideInMobile]="false" [specificHeight]="'48px'" [specificFontWeight]="'bold'"
			[specificFontSize]="'0.9rem'" [isFullWidth]="true" (buttonClicked)="register()">
		</app-common-button>
	</div>
	<div class="login-container ion-text-center mh-10">
		{{ 'Already have an account?' | translate }} <span class="login pl-5" (click)="openLoginModal()">{{ 'Login' |
			translate }}<span class="right-icon"><img src="assets/icon/right-arrow.svg" alt="Juman" height="100%"
					width="100%"></span></span>
	</div>
</ion-footer>

<ion-footer *ngIf="getPlatformService.isDesktop">
	<div class="login-container ion-text-center"
		[ngClass]="getPlatformService.isDesktop ? 'login-desktop':'login-mobile'">
		{{ 'Already have an account?' | translate }} <span class="login pl-10" (click)="openLoginModal()">{{ 'Login' |
			translate }}<span class="right-icon"
				[ngClass]="getPlatformService.isDesktop ? 'top-position-desktop':'top-position-mobile'"><img
					src="assets/icon/right-arrow.svg" alt="Juman" height="100%" width="100%"></span></span>
	</div>
</ion-footer>
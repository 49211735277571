import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, UrlSerializer } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { FirebaseAnalytics } from '@awesome-cordova-plugins/firebase-analytics/ngx';
import { JwtInterceptor } from './_helpers/http-interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './common/shared.module';
import { TranslateConfigService } from './services/translate-config.service';
// Import the functions you need from the SDKs you need
import { CustomUrlSerializer } from './services/url-serialiser.service';




// // Initialize Firebase
// isSupported().then(() => {
// 	const app = initializeApp(environment.firebaseConfig);
// 	const analytics = getAnalytics(app);
// 	const auth = getAuth(app)
// 	const firestore = getFirestore(app)
// })




@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule.withServerTransition({ appId: 'serverApp' }),
		IonicModule.forRoot({ animated: false }),
		AppRoutingModule,
		SharedModule,
		HttpClientModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: (HttpLoaderFactory),
				deps: [HttpClient]
			}
		})],
	providers: [
		TranslateConfigService,
		TranslateService,
		FirebaseAnalytics,
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		{ provide: UrlSerializer, useClass: CustomUrlSerializer },
		{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }
	],

	bootstrap: [AppComponent],
})
export class AppModule { }
export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, "./assets/language/", ".json");
}


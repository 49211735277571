<ng-container *ngIf="!iscreditInfo">
	<div class="account-information" *ngIf="!mobileView">
		<div class="account-info-grid" *ngIf="contactInformation">
			<ion-grid>
				<ion-row>
					<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class="pd-0">
						<div class="information-holder">
							<div class="information-label">
								{{"accountInfoComponent.contactInformation.heading"| translate}}
							</div>
							<div class="information-container">
								<div class="account-name">
									{{contactInformation.customer_name}}
								</div>
								<div class="account-email">
									{{contactInformation.customer_email}}
								</div>
								<div class="account-email">
									+966 {{contactInformation.mobile_no}}
								</div>
							</div>
							<div class="edit-btn-container" *ngIf="!mobileView">
								<!-- <span class="edit-btn" (click)="editProfileOpen()">
                                {{"accountInfoComponent.editBtn" | translate}}
                            </span>
                            <span class="vertical-separator"></span> -->
								<span class="change-password" (click)="goToChangePassword()">
									{{"accountInfoComponent.changePass" | translate}}
								</span>
							</div>
						</div>
					</ion-col>
					<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12">
						<div class="information-holder">
							<div class="information-label">
								{{"accountInfoComponent.accountSecurity.heading" | translate}}
							</div>
							<div class="information-container">
								<div class="security-note">
									<span>{{"2FAAccountDescription" | translate}}</span>
								</div>
							</div>
							<div class="twoF-btn-container">
								<ion-button fill="clear" class="twoF-btn" (click)="toggle2fa()">
									<img [ngClass]="commonService.domDirectionLTR ? 'mr-10' : 'ml-10'"
										src="./assets/icon/lockIcon.svg" alt="add" slot="start">
									<span
										*ngIf="!commonService.twoFaEnabled">{{"accountInfoComponent.enable2FactorBtnLabel"
										| translate}}</span>
									<span
										*ngIf="commonService.twoFaEnabled">{{"accountInfoComponent.disable2FactorBtnLabel"
										| translate}}</span>
								</ion-button>
							</div>
						</div>
					</ion-col>
				</ion-row>

				<ion-row class="mt-30">
					<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class="pd-0">
						<div class="information-holder">
							<div class="information-label">
								{{"accountInfoComponent.crNumber"| translate}}
							</div>
							<div class="information-container">
								<div class="account-name">
									{{contactInformation.customer_cr_no ? contactInformation.customer_cr_no : "--"}}
								</div>
								<div class="account-name pointer color-primary"
									*ngIf="contactInformation.customer_cr_url"
									(click)="openFile(contactInformation.customer_cr_url)">
									<i class="feather color-primary icon-file"></i>
									{{contactInformation.customer_cr_url ?
									getFileName(contactInformation.customer_cr_url) :
									"--"}}
								</div>
							</div>
						</div>
					</ion-col>
					<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12">
						<div class="information-holder">
							<div class="information-label">
								{{"accountInfoComponent.vatNumber"| translate}}
							</div>
							<div class="information-container">
								<div class="account-name">
									{{contactInformation.customer_vat_no ? contactInformation.customer_vat_no : "--"}}
								</div>
								<div class="account-name pointer color-primary"
									*ngIf="contactInformation.customer_vat_url"
									(click)="openFile(contactInformation.customer_vat_url)">
									<i class="feather color-primary icon-file"></i>
									{{contactInformation.customer_vat_url ?
									getFileName(contactInformation.customer_vat_url):"--"}}
								</div>
							</div>
						</div>
					</ion-col>
				</ion-row>
				<ion-row class="mt-30">
					<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class="p-0">
						<div class="information-holder">
							<div class="information-label">
								{{"accountInfoComponent.nationalAddress"| translate}}
							</div>
							<div class="information-container">
								<div class="account-name pointer color-primary"
									*ngIf="contactInformation.national_address_url"
									(click)="openFile(contactInformation.national_address_url)">
									<i class="feather color-primary icon-file"></i>
									{{contactInformation.national_address_url ?
									getFileName(contactInformation.national_address_url):"--"}}
								</div>
							</div>
						</div>
					</ion-col>
				</ion-row>

			</ion-grid>
		</div>
	</div>
	<div class="account-information" *ngIf="mobileView">
		<div class="account-info-grid" *ngIf="contactInformation">
			<ion-grid class="p-0">
				<ion-row>
					<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class="pd-0">
						<div class="common-input mb-10">
							<ion-item lines="none" mode="md">
								<img slot="start" src="assets/icon/email-icon.svg" alt="emailicon" class="input-icon">
								<ion-label class="input-color p-0">{{contactInformation.customer_email}}</ion-label>
							</ion-item>

						</div>

						<div class="common-input mb-10">
							<ion-item lines="none" mode="md">
								<img slot="start" src="assets/icon/user.svg" alt="juman" class="input-icon">
								<ion-label class="input-color p-0">{{contactInformation.customer_name}}</ion-label>
							</ion-item>

						</div>

						<div class="common-input mb-10">
							<ion-item lines="none" mode="md">
								<img slot="start" src="assets/icon/password-icon.svg" alt="password" class="input-icon">
								<ion-label class="input-color p-0 lbl-password ">12345678</ion-label>

								<ion-label slot="end" class="btn-label"
									(click)="openModal()">{{"accountInfoComponent.change" |
									translate}}</ion-label>
							</ion-item>


						</div>

					</ion-col>

				</ion-row>
			</ion-grid>
		</div>
	</div>
</ng-container>


<!-- ---------------------------------------- CreditDetails ----------------------------------------  -->
<ng-container *ngIf="iscreditInfo">
	<div class="account-information" *ngIf="!mobileView">
		<div class="account-info-grid" *ngIf="contactInformation">
			<ion-grid>
				<ng-container
					*ngIf="contactInformation?.customer_credit_data && contactInformation?.customer_credit_data.is_blocked != true">
					<ion-row>
						<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class="pd-0">
							<div class="information-holder">
								<div class="information-label">
									{{"creditComponent.customer_credit_limit"| translate}}
								</div>
								<div class="information-container">
									<div class="account-name">
										{{commonService.currencySymbol}}
										{{contactInformation?.customer_credit_data?.customer_credit_limit ||
										" --"}} </div>
								</div>
							</div>
						</ion-col>
						<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12">
							<div class="information-holder">
								<div class="information-label">
									{{"creditComponent.credit_time" | translate}}
								</div>
								<div class="information-container">
									<div class="account-name">
										{{contactInformation?.customer_credit_data?.credit_time || " --"}}
										{{'creditComponent.Days' |
										translate}} </div>
								</div>
							</div>
						</ion-col>
					</ion-row>

					<ion-row>
						<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class="pd-0">
							<div class="information-holder">
								<div class="information-label">
									{{"creditComponent.min_credit_order_value"| translate}}
								</div>
								<div class="information-container">
									<div class="account-name">
										{{commonService.currencySymbol}}
										{{contactInformation?.customer_credit_data?.min_credit_order_value ||
										" --"}} </div>
								</div>
							</div>
						</ion-col>
						<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12">
							<div class="information-holder">
								<div class="information-label">
									{{"creditComponent.max_credit_order_value" | translate}}
								</div>
								<div class="information-container">
									<div class="account-name">
										{{commonService.currencySymbol}}
										{{contactInformation?.customer_credit_data?.max_credit_order_value ||
										" --"}}
									</div>
								</div>
							</div>
						</ion-col>
					</ion-row>

					<ion-row>
						<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class="pd-0">
							<div class="information-holder">
								<div class="information-label">
									{{"creditComponent.min_orders_required"| translate}}
								</div>
								<div class="information-container">
									<div class="account-name">
										{{contactInformation?.customer_credit_data?.min_orders_required ||
										" --"}} </div>
								</div>
							</div>
						</ion-col>
						<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12">
							<div class="information-holder">
								<div class="information-label">
									{{"creditComponent.remainingCreditBalance" | translate}}
								</div>
								<div class="information-container">
									<div class="account-name">
										{{commonService.currencySymbol}}
										{{contactInformation?.customer_credit_data?.remaining_credit ||
										" --"}}
									</div>
								</div>
							</div>
						</ion-col>
					</ion-row>

					<ion-row>
						<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12">
							<div class="information-holder">
								<div class="information-label">
									{{"creditComponent.creditRatings" | translate}}
								</div>
								<div class="information-container"
									*ngIf="contactInformation && contactInformation.customer_credit_data">
									<div class="account-name">
										<div class="rating-container">
											<nz-rate [ngModel]="contactInformation.customer_credit_data.credit_rating"
												[nzAllowHalf]="true" nzDisabled></nz-rate>
										</div>

									</div>
								</div>
							</div>
						</ion-col>

					</ion-row>
				</ng-container>


				<ion-row>
					<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class="pd-0">
						<div class="information-holder">
							<div class="information-label">
								{{"creditComponent.eligible" | translate}}
							</div>
							<div class="information-container">
								<div class="account-name">
									{{contactInformation?.customer_credit_data?.is_eligible?('yes'|translate) :
									('no'|translate)}}
								</div>
							</div>
						</div>
					</ion-col>
					<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12">
						<div class="information-holder">
							<div class="information-label">
								{{"creditComponent.blocked" | translate}}
							</div>
							<div class="information-container">
								<div class="account-name">
									{{contactInformation?.customer_credit_data?.is_blocked? ('yes'|translate) :
									('no'|translate)}}

								</div>
							</div>
						</div>
					</ion-col>
				</ion-row>
				<ion-row>
					<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class="pd-0"
						*ngIf="contactInformation?.customer_credit_data?.is_blocked == true">
						<div class="account-name color-primary">
							{{"creditComponent.isBlockMsg"| translate}}
						</div>
					</ion-col>
					<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12"
						*ngIf="contactInformation?.customer_credit_data?.is_eligible == false">
						<div class="account-name color-primary">
							{{"creditComponent.isEligibleMsg" | translate}}
						</div>
					</ion-col>
				</ion-row>
			</ion-grid>
		</div>
	</div>
	<div class="account-information" *ngIf="mobileView">
		<div class="account-info-grid" *ngIf="contactInformation && !iscreditInfo">
			<ion-grid class="p-0">
				<ion-row>
					<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class="pd-0">
						<div class="common-input mb-10">
							<ion-item lines="none" mode="md">
								<img slot="start" src="assets/icon/email-icon.svg" alt="emailicon" class="input-icon">
								<ion-label class="input-color p-0">{{contactInformation.customer_email}}</ion-label>
							</ion-item>

						</div>

						<div class="common-input mb-10">
							<ion-item lines="none" mode="md">
								<img slot="start" src="assets/icon/user.svg" alt="juman" class="input-icon">
								<ion-label class="input-color p-0">{{contactInformation.customer_name}}</ion-label>
							</ion-item>

						</div>

						<div class="common-input mb-10">
							<ion-item lines="none" mode="md">
								<img slot="start" src="assets/icon/password-icon.svg" alt="password" class="input-icon">
								<ion-label class="input-color p-0 lbl-password ">12345678</ion-label>

								<ion-label slot="end" class="btn-label"
									(click)="openModal()">{{"accountInfoComponent.change" |
									translate}}</ion-label>
							</ion-item>


						</div>

					</ion-col>

				</ion-row>
			</ion-grid>
		</div>

		<!-- ---------------------------------------- Mobile CreditDetails ----------------------------------------  -->

		<div class="account-info-grid" *ngIf="contactInformation && iscreditInfo">
			<ion-grid class="p-0">
				<ion-row>
					<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class="pd-0">
						<div class="common-input mb-10">
							<ion-item lines="none" mode="md">
								<ion-label position="floating"
									class="input-color">{{'creditComponent.customer_credit_limit'|
									translate}}</ion-label>
								<ion-input readonly class="input-color p-0"
									[(ngModel)]="contactInformation.customer_credit_data.customer_credit_limit">
									<span slot="start">{{commonService.currencySymbol}}&nbsp;</span>
								</ion-input>
							</ion-item>
						</div>

						<div class="common-input mb-10">
							<ion-item lines="none" mode="md">
								<ion-label position="floating" class="input-color">{{'creditComponent.credit_time'|
									translate}} ({{'creditComponent.Days'|translate}})</ion-label>
								<ion-input readonly class="input-color p-0"
									[(ngModel)]="contactInformation.customer_credit_data.credit_time">
								</ion-input>
							</ion-item>
						</div>

						<div class="common-input mb-10">
							<ion-item lines="none" mode="md">
								<ion-label position="floating"
									class="input-color">{{'creditComponent.min_credit_order_value'|
									translate}}</ion-label>
								<ion-input readonly class="input-color p-0"
									[(ngModel)]="contactInformation.customer_credit_data.min_credit_order_value">
									<span slot="start">{{commonService.currencySymbol}}&nbsp;</span>
								</ion-input>
							</ion-item>
						</div>

						<div class="common-input mb-10">
							<ion-item lines="none" mode="md">
								<ion-label position="floating"
									class="input-color">{{'creditComponent.max_credit_order_value'|
									translate}}</ion-label>
								<ion-input readonly class="input-color p-0"
									[(ngModel)]="contactInformation.customer_credit_data.max_credit_order_value">
									<span slot="start">{{commonService.currencySymbol}}&nbsp; </span>
								</ion-input>
							</ion-item>
						</div>

						<div class="common-input mb-10">
							<ion-item lines="none" mode="md">
								<ion-label position="floating"
									class="input-color">{{'creditComponent.min_orders_required'|
									translate}}</ion-label>
								<ion-input readonly class="input-color p-0"
									[(ngModel)]="contactInformation.customer_credit_data.min_orders_required">
								</ion-input>
							</ion-item>
						</div>

						<div class="common-input mb-10">
							<ion-item lines="none" mode="md">
								<ion-label position="floating"
									class="input-color">{{'creditComponent.remainingCreditBalance'|
									translate}}</ion-label>
								<ion-input readonly class="input-color p-0"
									[(ngModel)]="contactInformation.customer_credit_data.remaining_credit">
									<span slot="start">{{commonService.currencySymbol}}&nbsp; </span>
								</ion-input>
							</ion-item>
						</div>
						<div class="common-input mb-10">
							<ion-item lines="none" mode="md">
								<ion-label position="floating" class="input-color">{{'creditComponent.creditRatings'|
									translate}}</ion-label>
								<ion-input readonly class="input-color p-0"
									[(ngModel)]="contactInformation.customer_credit_data.credit_rating">

								</ion-input>
							</ion-item>
						</div>
					</ion-col>
				</ion-row>

				<ion-row>
					<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12" class="pd-0"
						*ngIf="contactInformation?.customer_credit_data?.is_blocked == true">
						<p class="account-name color-primary">
							{{"creditComponent.isBlockMsg"| translate}}
						</p>
					</ion-col>
					<ion-col size-sm="12" size-md="6" size-lg="6" size-xl="6" size-xs="12"
						*ngIf="contactInformation?.customer_credit_data?.is_eligible == false">
						<p class="account-name color-primary">
							{{"creditComponent.isEligibleMsg" | translate}}
						</p>
					</ion-col>
				</ion-row>
			</ion-grid>
		</div>
	</div>
</ng-container>
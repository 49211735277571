import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { CapacitorCookies } from '@capacitor/core';


@Injectable({
	providedIn: 'root'
})
export class CapacitorCookieService {

	constructor(@Inject(DOCUMENT) private document: Document) { }
	getCookies = () => {
		return this.document.cookie;
	};

	setCookie(key, value) {
		this.document.cookie = key + '=' + value;
	};

	setCapacitorCookie = async () => {
		await CapacitorCookies.setCookie({
			// url: 'http://example.com',
			key: 'language',
			value: 'en',
		});
	};

	deleteCookie = async () => {
		await CapacitorCookies.deleteCookie({
			url: 'https://example.com',
			key: 'language',
		});
	};

	clearCookiesOnUrl = async () => {
		await CapacitorCookies.clearCookies({
			url: 'https://example.com',
		});
	};

	clearAllCookies = async () => {
		await CapacitorCookies.clearAllCookies();
	};
}
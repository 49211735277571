// Angular
import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { FirebaseAnalytics } from '@awesome-cordova-plugins/firebase-analytics/ngx';
import { Platform } from '@ionic/angular';
import { initializeApp } from 'firebase/app';
import { GetPlatformService } from './get-platform.service';

import { getAnalytics, isSupported, logEvent, setCurrentScreen } from "firebase/analytics";

import * as firebase from 'firebase/app';
import { environment } from 'src/environments/environment';

// Ionic

declare var window: any;


@Injectable({
	providedIn: 'root'
})
export class CustomFirebaseAnalyticsProvider {

	fbAnalyctics: any;
	app: any;
	// Initialize Firebase
	constructor(
		private platform: Platform,
		private getPlatformService: GetPlatformService,
		private firebaseAnalytics: FirebaseAnalytics,
		@Inject(PLATFORM_ID) private platformId: Object,
	) {
		if (!isPlatformServer(this.platformId)) {
			isSupported().then(() => {
				if (this.getPlatformService.isDesktop) {
					this.app = initializeApp(environment.firebaseConfig);
				}
			})
		}
	}

	// Tracks an 'screen_view' event in Firebase Analytics
	trackView(screenName: string) {
		if (!isPlatformServer(this.platformId)) {
			isSupported().then(() => {
				if (this.getPlatformService.isDesktop) {
					this.fbAnalyctics = getAnalytics(firebase.getApp());
					setCurrentScreen(this.fbAnalyctics, screenName);
				} else {
					this.firebaseAnalytics.setCurrentScreen(screenName);
				}
			})
		}


	}

	// Tracks a custom event in Firebase Analytics
	trackEvent(eventName: string, eventParams: any) {
		if (!isPlatformServer(this.platformId)) {
			isSupported().then(() => {
				if (this.getPlatformService.isDesktop) {
					this.fbAnalyctics = getAnalytics(firebase.getApp());
					logEvent(this.fbAnalyctics, eventName, { eventParams });
				}
				else {
					this.firebaseAnalytics.logEvent(eventName, { eventParams });

				}
			})
		}

	}



}
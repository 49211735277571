import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NavController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { GetPlatformService } from "src/app/services/get-platform.service";
import { CommonService } from "../../services/common.service";
@Component({
	selector: "app-cart-header",
	templateUrl: "./cart-header.component.html",
	styleUrls: ["./cart-header.component.scss"],
})
export class CartHeaderComponent implements OnInit {
	@Input() countIndex: any = 0;

	constructor(
		public getPlatformService: GetPlatformService,
		public router: Router,
		public navCtrl: NavController,
		public commonService: CommonService,
		public translate: TranslateService
	) { }

	ngOnInit() { }

	gotToContactUs() {
		this.navCtrl.navigateForward("/contact-us", { animated: false });
	}

	navigate(flag) {
		if (this.countIndex < 1 || this.countIndex == 5) {
			return;
		}

		this.commonService.localStorageSet("fromCartHeader", "true");

		if (this.countIndex > 1 && flag == "cart") {
			this.router.navigate(["/cart"], { state: { intro: true } });
			return;
		}
		if (this.countIndex > 2 && flag == "shipping") {
			this.router.navigate(["/checkout-step-1"], { state: { intro: true } });
			return;
		}
		if (this.countIndex > 3 && flag == "payment") {
			this.router.navigate(["/checkout-step-2"], { state: { intro: true } });
			return;
		}
		if (this.countIndex > 4 && flag == "review") {
			this.router.navigate(["/checkout-step-review"], {
				state: { intro: true },
			});
			return;
		}
	}
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class LanguageService {
	defaultLanguage: string = '';

	private defaultLanguageSubject = new BehaviorSubject<string>(this.defaultLanguage);
	defaultLanguage$ = this.defaultLanguageSubject.asObservable();

	constructor() { }

	// Method to update default language
	updateDefaultLanguage(language: string): void {
		this.defaultLanguage = language;
		this.defaultLanguageSubject.next(language);
	}
}

<div class="add-review-container pt-20" [ngClass]="getPlatformService.isDesktop? 'pl-30 pr-30':null">
	<div class="header-line" *ngIf="!getPlatformService.isDesktop">
	</div>
	<div class="modal-close ion-text-right" (click)="closeModal()">
		<img src="assets/icon/close.svg" alt="Close" height="100%" width="100%" />
	</div>
	<div class="add-review-header">
		{{'addReview' | translate}}
	</div>
	<div class="horizontal-line" *ngIf="!getPlatformService.isDesktop"></div>
	<div class="rating-container">
		<!-- <ionic3-star-rating class="add-review-rating" #rating activeIcon="custom-star-fill" halfStar="false"
			defaultIcon="custom-star-default" activeColor="#FACD2E" defaultColor="#758D96" fontSize="40px"
			readonly="false" [rating]="product_rating" (ratingChanged)="ratingChange($event)">
		</ionic3-star-rating> -->

		<nz-rate class="add-review-rating" #rating [ngModel]="product_rating" [nzAllowHalf]="false"
			(ngModelChange)="ratingChange($event)"></nz-rate>


	</div>
	<form [formGroup]="reviewFormGroup" class="reviewFormContainer">
		<div class="common-text-area review-title pb-15">
			<ion-item lines="none" mode="md" *ngIf="!isLoggedIn">
				<ion-label position="floating" class="text-uppercase">{{'field.CustomerName' |translate}}<sup
						class="required-star">*</sup></ion-label>
				<ion-input formControlName="customer_name" maxlength="500"></ion-input>
			</ion-item>
			<ng-container
				*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'customer_name', 'formName': reviewFormGroup} }">
			</ng-container>
		</div>

		<div class="common-text-area review-title pb-15">
			<ion-item lines="none" mode="md">
				<ion-label position="floating" class="text-uppercase">{{'field.ReviewTitle' |translate}}<sup
						class="required-star">*</sup></ion-label>
				<ion-textarea rows="1" formControlName="review_title" maxlength="100"></ion-textarea>
			</ion-item>
			<ng-container
				*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'review_title', 'formName': reviewFormGroup} }">
			</ng-container>
		</div>
		<div class="common-text-area">
			<ion-item lines="none" mode="md">
				<ion-label position="floating" class="text-uppercase">{{'field.ReviewDescription' |translate}}<sup
						class="required-star">*</sup></ion-label>
				<ion-textarea rows="4" formControlName="review_description" maxlength="3000"></ion-textarea>
			</ion-item>
			<ng-container
				*ngTemplateOutlet="validationMessages; context:{ $implicit: {'controlName': 'review_description', 'formName': reviewFormGroup} }">
			</ng-container>
		</div>
	</form>
</div>
<ion-footer>
	<div class="footer-button-container">
		<div class="cancel" (click)="closeModal()">
			{{ 'Cancel' | translate }}
		</div>
		<div class="footer-button">
			<app-common-button color="#FFF" background="#38C2D4" type="solid" class="width-100"
				title=" {{ 'Submit' | translate }}" [isIcon]="false" [isHideInMobile]="false" [specificWidth]="'122px'"
				[specificHeight]="'46px'" [specificFontSize]="'0.9375rem'" (click)="onSubmit()">
			</app-common-button>
		</div>
	</div>
</ion-footer>

<ng-template let-formObject #validationMessages>
	<ng-container *ngFor="let validation of validation_messages[formObject.controlName]">
		<ion-text color="danger" class="error-msg"
			*ngIf="formObject.formName.controls[formObject.controlName].hasError(validation.type) && formObject.formName.controls[formObject.controlName].touched">
			{{validation.message | translate }}
		</ion-text>
	</ng-container>
</ng-template>
import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router';
import { LanguageService } from './language.service';

@Injectable({
	providedIn: 'root'
})
export class CustomUrlSerializer implements UrlSerializer {
	private defaultUrlSerializer = new DefaultUrlSerializer();
	private defaultLanguage;

	constructor(private languageService: LanguageService,
		@Inject(PLATFORM_ID) private platformId: Object,
	) {
		this.languageService.defaultLanguage$.subscribe(language => {
			this.defaultLanguage = language;
		});

		if (!isPlatformServer(this.platformId)) {
			this.defaultLanguage = localStorage.getItem('locale');
		}
	}

	serialize(tree: UrlTree): string {
		let path = this.defaultUrlSerializer.serialize(tree);
		path = `/${this.defaultLanguage}${path}`;
		return path;
	}

	parse(url: string): UrlTree {
		const trimmedUrl = url.replace(`/${this.defaultLanguage}`, '');
		return this.defaultUrlSerializer.parse(trimmedUrl);
	}
}

import { Component, Input, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { TranslateService } from "@ngx-translate/core";
import { GetPlatformService } from 'src/app/services/get-platform.service';
import { LoginPage } from '../../pages/login/login.page';
import { RegisterPage } from '../../pages/register/register.page';
import { ModalService } from '../../services/modal.service';
import { environment } from './../../../environments/environment';
import { CommonService } from './../../services/common.service';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
	@Input() showOnlyLowFooter?: boolean = false;
	isLoggedIn: boolean = false;
	currentYear = new Date().getFullYear();
	supplierUrl = environment.supplierUrl;

	constructor(private modalService: ModalService,
		private router: Router,
		private navCtrl: NavController,
		public getPlatformService: GetPlatformService,
		public commonService: CommonService, public translate: TranslateService) {
		this.commonService.eventObservable.subscribe(data => {
			if (data.event == 'profileData') {
				this.isLoggedIn = this.commonService.localStorageGet('accessToken') ? true : false;
			}
			if (data.event == 'logoutCall') {
				this.isLoggedIn = this.commonService.localStorageGet('accessToken') ? true : false;
			}
		});
	}

	ngOnInit() {
		this.isLoggedIn = this.commonService.localStorageGet('accessToken') ? true : false;

	}

	openRegisterModal() {
		if (this.getPlatformService.isDesktop) {
			setTimeout(() => {
				let options: any = {
					id: "registerModal",
					backdropDismiss: false
				};
				options['component'] = RegisterPage;
				this.modalService.openModal(options)
			}, 300);
		} else {
			this.navCtrl.navigateForward('/register');
		}

	}

	// Go to relevant page
	navigate(page) {
		this.router.navigate(['/' + page]);
	}

	// Go to relevant page
	navigateToFaq(page, fromPage?) {
		const navigationExtras: NavigationExtras = {
			queryParams: {
				fromPage: fromPage,
			},
		};
		this.router.navigate(['/' + page], navigationExtras);
	}

	gotToCareers() {
		this.navCtrl.navigateForward('/career', { animated: false });
	}
	gotToContactUs() {
		this.navCtrl.navigateForward('/contact-us', { animated: false });
	}

	openLoginModal() {
		if (this.getPlatformService.isDesktop) {
			setTimeout(() => {
				let options: any = {
					id: "loginModal",
					backdropDismiss: false
				};
				options['component'] = LoginPage;
				this.modalService.openModal(options)
			}, 300);
		} else {
			this.navCtrl.navigateForward('/login');
		}

	}

	goToHome() {
		this.navCtrl.navigateBack("/home", { animated: false })
	}

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { URLS } from '../../_config/api.config';
import { ApiService } from '../../services/api-data.service';
import { CommonService } from '../../services/common.service';
import { ModalService } from '../../services/modal.service';
import { ToastService } from '../../services/toast.service';
import { GetPlatformService } from './../../services/get-platform.service';

@Component({
	selector: 'app-cancel-product',
	templateUrl: './cancel-product.component.html',
	styleUrls: ['./cancel-product.component.scss'],
})
export class CancelProductComponent implements OnInit {
	isCancel = true;
	orderData: any = {};
	invoiceId: any;
	rows = 3;
	mobileClasses = false;
	reasonsList = [];
	isLoading = false;
	cancelForm: FormGroup;
	public validation_messages = {
		reason: [
			{ type: 'required', message: 'cancelReasonRequired' },
		],
		reasonDesc: [
			{ type: 'required', message: 'cancelReasonDescRequired' },
			{ type: 'minlength', message: 'cancelReasonDescLength' },
		],
	}
	private subscription: Subscription = new Subscription();
	constructor(private modalService: ModalService,
		private toastService: ToastService,
		private dataService: ApiService,
		private formBuilder: FormBuilder,
		public commonService: CommonService,
		public navCtrl: NavController,
		public translateService: TranslateService,
		private router: Router,
		public getPlatformService: GetPlatformService) { }

	ngOnInit() {
		this.isLoading = false;
		this.cancelForm = this.formBuilder.group({
			reason: new FormControl(null, {
				validators: Validators.compose([Validators.required]),
			}),
			reasonDesc: new FormControl(null, {
				validators: Validators.compose([Validators.required, Validators.minLength(5)]),
			})
		})
		this.getReasons();
	}

	closeModal(data?) {
		if (this.isLoading) {
			return
		}
		this.modalService.dismissModalwithData(data);
	}

	getReasons() {
		this.subscription.add(
			this.dataService.get(URLS.reasonsList).subscribe(data => {
				if (data['code'] === 200) {
					let list = data['data'] && data['data'].length > 0 ? data['data'] : [];
					this.reasonsList = [];
					list.forEach(element => {
						let obj = {
							id: element.reason_id,
							name: element.reason_en,
							name_ar: element.reason_ar
						}
						this.reasonsList.push(obj);
					});
				} else {
					this.toastService.displayToast(data['message'], null, 'danger');
				}
			}, error => {
				if (error && error['message']) {
					this.toastService.displayToast(error['message'], null, 'danger');
				}

			})
		)
	}

	// for order navigation
	gotoOrderDetails(id, orderNo) {
		this.router.navigate(["/home/account-details/my-orders/", id], {
			state: {
				breadcrumb:
					this.translateService.instant("orderDetailsComponent.orders") +
					orderNo,
				url: "/home/account-details/my-orders/" + id,
			},
		});
	}

	gotoOrderDetailsFromMobile(id, orderNo) {
		this.navCtrl.navigateForward(['/home/order-details-mobile'], {
			queryParams: {
				invoiceId: id
			}
		})
	}

	submit() {
		for (const i in this.cancelForm.controls) {
			this.cancelForm.controls[i].markAsDirty();
			this.cancelForm.controls[i].updateValueAndValidity();
			this.cancelForm.controls[i].markAsTouched();
		}
		if (this.cancelForm.invalid) {
			return
		}
		if (!this.invoiceId) {
			return;
		}
		const params = {
			reason_description: this.cancelForm.value.reasonDesc,
			reason_id: +this.cancelForm.value.reason,
			invoice_item_id: +this.orderData.invoice_item_id,
			invoice_id: +this.invoiceId,
			shipping_status: this.orderData.is_cancellation_allowed ? "CANCELLATION_REQUESTED" : this.orderData.is_return_allowed ? "RETURN_REQUESTED" : "",
		}
		if (this.isLoading) {
			return
		}
		this.isLoading = true;
		this.subscription.add(
			this.dataService.post(URLS.cancelOrderItem, params).subscribe(data => {
				if (data['code'] === 200) {
					this.isLoading = false;
					this.closeModal(true)
				} else {
					this.isLoading = false;
					this.toastService.displayToast(data['message'], null, 'danger');
				}
			}, error => {
				this.isLoading = false;
				this.toastService.displayToast(error['message'], null, 'danger');
			})
		);
	}
}

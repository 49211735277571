import { isPlatformServer } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
@Component({
	selector: 'app-not-found',
	templateUrl: './not-found.component.html',
	styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {

	constructor(
		@Inject(PLATFORM_ID) private platformId: any,
	) { }

	ngOnInit(): void {
		if (!isPlatformServer(this.platformId)) {
		}
	}
}

<div class="two-fa-container">
	<div class="modal-close-mobile text-right" (click)="closeModal()">
		<img src="assets/icon/modal-close-mobile.svg" alt="juman">
	</div>
	<div class="header-line">
	</div>
	<div class="two-fa-header pb-10">
		{{'filterBy' | translate}}
	</div>
	<div class="filter-by-options ph-20">
		<ion-list>
			<form [formGroup]="dateRange">
				<ion-item lines="none" id="fromDatePicker" class="with-bottom-border list-item">
					<div>
						<ion-label class="filter-label"> {{'fromDate' | translate}}</ion-label>
						<span class="selected-date">{{fromDate ? (fromDate | date:'dd/MM/yyyy') : ''}}</span>
					</div>
					<img src="./assets/icon/rightArrowFilter.svg" alt="right-arrow" slot="end">
				</ion-item>
				<ion-popover trigger="fromDatePicker" size="cover" id="datePopover">
					<ng-template>
						<ion-datetime presentation="date" [min]="'2000-01-01'" [max]="toDate ? toDate : maxDate"
							formControlName="from" #filterFromDate [(ngModel)]="fromDate"
							[ngModelOptions]="{standalone: true}" [showDefaultButtons]="true" mode="md"
							cancelText="{{'Cancel' | translate}}" doneText="{{'done' | translate}}"></ion-datetime>
					</ng-template>
				</ion-popover>
				<div class="divider-line"></div>
				<ion-item lines="none" id="toDatePicker" class="with-bottom-border list-item">
					<div>
						<ion-label class="filter-label"> {{'toDate' | translate}}</ion-label>
						<span class="selected-date">{{toDate ? (toDate | date:'dd/MM/yyyy') : ''}}</span>
					</div>
					<img src="./assets/icon/rightArrowFilter.svg" alt="right-arrow" slot="end">
				</ion-item>
				<ion-popover trigger="toDatePicker" size="cover" id="datePopover">
					<ng-template>
						<ion-datetime presentation="date" [min]="fromDate ? fromDate : '2000-01-01'" [max]="maxDate"
							[ngModelOptions]="{standalone: true}" formControlName="to" #filterToDate
							[(ngModel)]="toDate" [showDefaultButtons]="true" mode="md"
							cancelText="{{'Cancel' | translate}}" doneText="{{'done' | translate}}"></ion-datetime>
					</ng-template>
				</ion-popover>
				<div class="divider-line"></div>
			</form>
			<ion-item *ngIf="false" lines="none" class="list-item" (click)="select.open()"
				[ngClass]="fromOrdersList ? 'order-list-status':''">
				<div>
					<ion-label class="filter-label"> {{'status' | translate}}
					</ion-label>
					<ion-button fill="clear" class="status-btn "
						[ngClass]="{'under-review' : selectedStatus == 1,'returned':selectedStatus == 2,'reject':selectedStatus == 3}"
						*ngIf="selectedStatus && !fromOrdersList && !fromQuotesList">
						{{ selectedStatus == 1 ? ('pending' | translate) : selectedStatus == 2 ? ('verified' |
						translate) :
						('reject' | translate) }}
					</ion-button>

					<ion-button fill="clear" class="status-btn "
						[ngClass]="{'under-review' : selectedStatus == 1,'returned':selectedStatus == 0,'reject':selectedStatus == 3}"
						*ngIf="selectedStatus && fromQuotesList">
						{{ selectedStatus == 'true' ? ('myQuoteComponent.completed' | translate) :
						('myQuoteComponent.requested' | translate) }}
					</ion-button>

					<ion-button fill="clear" *ngIf="selectedStatus && fromOrdersList" class="order-status-btn"
						[ngClass]="{'btn-green' : selectedStatus == 'CONFIRMED' || selectedStatus == 'DISPATCHED' ,'btn-grey': selectedStatus == 'NOT_YET_DELIVERED' ,'btn-black':selectedStatus == 'DELIVERED','btn-red':selectedStatus == 'CANCELLED'}">{{selectedStatus
						== 'CANCELLED'
						? ('cancelled' | translate) :selectedStatus == 'DISPATCHED' ?
						('dispatched' |
						translate) :selectedStatus == 'DELIVERED' ? ('delivered' |
						translate)
						:selectedStatus ==
						'NOT_YET_DELIVERED'? ('notYetDelivered' | translate) :('confirmed' |
						translate)}}
					</ion-button>
				</div>
				<ion-select cancelText="{{'Cancel'|translate}}" okText="{{'Ok' | translate}}" mode="md"
					[interfaceOptions]="statusInterface" #select class="filterby-select" [(ngModel)]="selectedStatus">
					<ion-select-option [value]="data.value" *ngFor="let data of statusList">{{data.label
						| translate}}</ion-select-option>
				</ion-select>
				<img src="./assets/icon/rightArrowFilter.svg" alt="right-arrow" slot="end">
			</ion-item>


			<ion-item lines="none" class="list-item" (click)="select.open()"
				[ngClass]="fromOrdersList ? 'order-list-status':''">
				<div>
					<ion-label class="filter-label"> {{'ordersComponent.paymentMode'|translate}}
					</ion-label>
					<ion-button fill="clear" class="status-btn under-review"
						*ngIf="selectPaymentMode && !fromOrdersList && !fromQuotesList">
						{{ selectPaymentMode}}
					</ion-button>

					<ion-button fill="clear" class="status-btn under-review'"
						*ngIf="selectPaymentMode && fromQuotesList">
						{{ selectPaymentMode }}
					</ion-button>

					<ion-button fill="clear" *ngIf="selectPaymentMode && fromOrdersList"
						class="order-status-btn btn-green">
						{{selectPaymentMode}}
					</ion-button>
				</div>
				<ion-select cancelText="{{'Cancel'|translate}}" okText="{{'Ok' | translate}}" mode="md"
					[interfaceOptions]="statusInterface" #select class="filterby-select"
					[(ngModel)]="selectPaymentMode">
					<ion-select-option [value]="data.value" *ngFor="let data of payment_mode_filter">{{
						commonService.domDirectionLTR ? data.label : data.label_ar}}</ion-select-option>
				</ion-select>
				<img src="./assets/icon/rightArrowFilter.svg" alt="right-arrow" slot="end">
			</ion-item>

		</ion-list>
	</div>
	<ion-footer>
		<div class="footer-button-container ph-20">
			<div class="apply-filter-btn clear-btn">
				<ion-button fill="clear" class="red-btn" (click)="clear()">{{'clear' | translate }}</ion-button>
			</div>
			<div class="apply-filter-btn">
				<ion-button fill="clear" class="red-btn" (click)="apply()">{{'apply' | translate }}</ion-button>
			</div>
		</div>
	</ion-footer>
</div>